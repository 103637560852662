import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/Header/Header"
import { menu, offers, row1, row2, system1, system3, system2 } from "../mock"
import { Link } from "gatsby"
import Footer from "../components/Footer/Footer"


/**
 *
 * background-image: url(/static/players-2369e22….png);
 background-position: center 12%;
 background-repeat: no-repeat;
 background-color: transparent;
 background-size: 47%;
 mix-blend-mode: luminosity;
 width: 100%;
 *
 *
 * **/
const IndexPage = () => (
  <Layout>
    <SEO title="Nos fonctionnalités"/>

    <Header
      style={{
        maxHeight: "780px"
      }}
      withActivity={true}>
      <div className={"home-container"} style={{ marginTop: "4rem" }}>
        <div className={"home-title home-color"}>
          Nos fonctionnalités
        </div>
        <div className={"home-menu"}>
          {menu.map((it, i) => (
            <Link to={"#" + it.url} key={"small-index-menu-" + i}>
              <div
                className={"small-menu"}
                style={{ background: it.background }}
              >
                <img
                  src={require("../images/" + it.url + ".svg")}
                  alt={it.name}
                />
                <span>{it.name}</span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Header>

    <div
      id={"function"}
      className={"page-container"}
      style={{}}
    >
      <p className={"page-description lg-screen"}>
        De nombreuses fonctionnalités, vous permettant <br/>
        de gérer au quotidien votre complexe sportif.
      </p>
      <p className={"page-description small-screen"}>
        De nombreuses fonctionnalités,<br/> vous permettant
        de gérer au quotidien <br/> votre complexe sportif.
      </p>

      <div className={"page-content"}>
        <div
          className={"image-container"}
          style={{ justifyContent: "center", marginTop: "4rem" }}
        >
          <img
            src={require("../images/content/Planning.png")}
            alt={"planning"}
            style={{
              width: "476px",
              height: "400px"
            }}
          />
        </div>

        <div id={"reservation"} className={"page-instruction"}>
          <svg
            preserveAspectRatio={"none"}
            className={"bg-func-side"}
            width="513" height="475" viewBox="0 0 513 475" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M115.946 285.037C246.106 89.8976 133.106 63.0985 233.217 60.0673C333.328 57.0362 428.534 82.6636 458.307 177.107C488.081 271.55 435.577 372.272 341.036 402.076C246.495 431.881 -14.2149 480.176 115.946 285.037Z"
              fill="url(#paint1_linear)"/>
            <defs>
              <linearGradient id="paint1_linear" x1="310.98" y1="411.552" x2="201.237" y2="63.4403"
                              gradientUnits="userSpaceOnUse">
                <stop stopColor="#4B48F4"/>
                <stop offset="1" stopColor="#B228B5" stopOpacity="0"/>
              </linearGradient>
            </defs>
          </svg>


          <img src={require("../images/content/calendar.svg")}
               id={"calendar-fun"}
               alt={"calendar"}/>

          <h1 style={{ color: "#B228B5", paddingTop: "4rem" }}>Planning</h1>
          <span>Gestion des plannings en temps réel</span>
          <p>
            - Gestion et partage des plannings avec tous vos collaborateurs.<br/>
            <br/>
            - Création et suppression d’un rendez-vous en un clic, déplacement en « glisser-déposer ».
            <br/><br/>
            Proposez la prise de rendez-vous 24h/7j sur Internet :<br/>
            - A chaque rendez-vous pris sur Internet, votre agenda se met à jour automatiquement.
          </p>

          <span>Une application Manager</span>
          <p>
            Parce que vous n’êtes pas tout le temps derrière votre PC, avec <br/>
            notre application dédiée, prenez les réservations sans être <br/>
            devant votre comptoir.
          </p>

        </div>


      </div>


      <div id={"crm"} className={"page-content"} style={{ flexDirection: "row-reverse" }}>
        <svg preserveAspectRatio={"none"}
             className={"bg-func-side"}
             width="64" height="396" viewBox="0 0 64 396" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M24.8594 194.409C-86.0875 353.769 -25.571 418.32 -84.0703 388.819C-142.57 359.317 -193 301.779 -193 194.409C-193 87.04 -144.231 0 -84.0703 0C-23.9101 0 135.806 35.0491 24.8594 194.409Z"
            fill="url(#paint0_linear)"/>
          <defs>
            <linearGradient id="paint0_linear" x1="-64.9443" y1="0" x2="-64.9443" y2="395.756"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#1378E5"/>
              <stop offset="1" stopColor="#B7B6FF" stopOpacity="0.67"/>
            </linearGradient>
          </defs>
        </svg>


        <div className={"image-container"}>
          <img src={require("../images/content/client.png")}
               style={{
                 width: 605,
                 height: 429
               }}
               alt={"CRM"}/>
        </div>
        <div className={"page-instruction"}>
          <img src={require("../images/content/crmm.svg")}
               id={"crm-fun"}
               alt={"calendar"}/>

          <h1 style={{ color: "#1378E5", textAlign: "center" }}>CRM</h1>
          <span style={{ color: "#1378E5", marginBottom: 0, marginTop: "0.8rem" }}>Base de données clients et réservations</span>
          <span style={{ color: "#1378E5", marginBottom: "1.2rem", marginLeft: "8rem" }}>Un outil de pilotage de votre activité</span>
          <p style={{ marginLeft: "8rem" }}>
            Créer et développer une base client avec des données <br/> fiables pour communiquer régulièrement auprès
            d'eux.<br/>
            Améliorez votre connaissance clients et créez une base de <br/> données adaptée à votre activité.
          </p>
        </div>
      </div>

      <div id={"caisse"} className={"page-content"}>
        <svg
          preserveAspectRatio={"none"}
          className={"bg-func-side"}
          width="182" height="849" viewBox="0 0 182 849" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M124.605 410.366C314.098 118.614 275.115 -24.2372 326.78 48.2658C378.445 120.769 409.34 247.279 363.708 461.337C318.076 675.394 227.56 837.512 161.533 823.437C95.5068 809.362 -64.8876 702.118 124.605 410.366Z"
            fill="url(#paint3_linear)"/>
          <defs>
            <linearGradient id="paint3_linear" x1="140.542" y1="818.962" x2="308.738" y2="29.9601"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#F44881"/>
              <stop offset="1" stopColor="#B7B6FF" stopOpacity="0.67"/>
            </linearGradient>
          </defs>
        </svg>


        <div className={"image-container"}>
          <img
            style={{
              width: 486,
              height: 335
            }}
            src={require("../images/content/caisse-1.png")}
            alt={"planning"}
          />
        </div>
        <div className={"page-instruction"}>
          <h1 style={{ color: "#F44881", textAlign: "center" }}>Caisse</h1>
          <span style={{ color: "#F44881", marginBottom: 0, marginTop: "0.8rem" }}>Un logiciel de caisse simple et intuitif</span>
          <span className={"with-mg"} style={{
            color: "#F44881"
          }}>une solution adaptée à vos besoins</span>
          <span style={{ color: "#F44881", marginBottom: "1rem", marginTop: "0.8rem", opacity: 0.67 }}>Simplifiez vos encaissements </span>
          <span style={{
            color: "#F44881",
            marginBottom: "1rem",
            marginTop: "0rem",
            opacity: 0.67
          }}> et vos commandes</span>
          <p>
            Enregistrez toutes vos réservations, abonnements et ventes du bar rapidement.<br/>
            Vous pouvez au choix utiliser un encaissement global ou au détail.<br/>
            <br/>
            Multi paiement, scan des codes barres, ticket en attente, sont disponibles dans notre application.<br/>
            <br/>
            Tout est fait pour vous faire gagner du temps.<br/>
          </p>
        </div>
      </div>


      <div className={"page-content"}>
        <div className={"page-instruction"}>

          <img src={require("../images/content/hand.svg")}
               id={"hand-fun"}
               alt={"calendar"}/>
          <span style={{ color: "#F44881", opacity: 0.67 }}>
           Maîtrisez vos abonnements <br/> et programmes de fidélité
          </span>
          <p>
            Fidélisez vos clients grâce à notre fonctionnalité de programmes personnalisés. <br/>
            Ainsi, informez à tout moment vos clients de vos réductions grâce aux notifications qu'ils reçoivent.<br/>
            <br/>
            Vos abonnements seront gérés plus facilement au quotidien.<br/>
            En créant vos cartes de fidélité, vos clients obtiennent leur carte de fidélité et <br/> abonnement
            directement sur leur smartphone via notre application.
          </p>
        </div>
      </div>

      <div className={"page-instruction"}>


        <h1 style={{ color: "#FF512F", textAlign: "center" }}>Service vidéo</h1>
        <span style={{
          color: "#FF512F",
          marginBottom: 0,
          marginTop: "0.8rem",
          alignSelf: "center",
          marginLeft: "8rem"
        }}>Système vidéo automatisé</span>
      </div>
      <div id={"video"} className={"page-content"} style={{ flexDirection: "row-reverse" }}>
        <svg
          className={"bg-func-side"}
          preserveAspectRatio={"none"} width="180" height="601" viewBox="0 0 180 601" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M115.627 295.232C-71.0811 537.239 30.7598 635.266 -67.6864 590.465C-166.133 545.663 -251 458.285 -251 295.232C-251 132.18 -168.928 0 -67.6864 0C33.5549 0 302.335 53.2261 115.627 295.232Z"
            fill="url(#paint4_linear)"/>
          <defs>
            <linearGradient id="paint4_linear" x1="-66.8377" y1="-32.1654" x2="134.414" y2="301.347"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#FF512F"/>
              <stop offset="1" stopColor="#F09819" stopOpacity="0.6"/>
            </linearGradient>
          </defs>
        </svg>
        <div className={"image-container"}>
          <img src={require("../images/content/video-2.png")}
               style={{
                 width: 615,
                 height: 437
               }}
               alt={"CRM"}/>
        </div>
        <div className={"page-instruction"}>

          <img src={require("../images/content/camera1.svg")}
               id={"camera-fun"}
               alt={"calendar"}/>

          <span style={{ color: "#FF512F" }} className={"with-mg"}>Développez votre club house et votre audience</span>
          <p>
            Direct live, streaming ou replay, à vous de choisir le mode de diffusion.<br/>
            Notre sytème automatisé s’occupe de tout !<br/>
            <br/>
            Les réservations sont filmées et mise en ligne immédiatement.
            <br/><br/>
            Faite de votre club, un lieu de vie incontournable pour une 3è mi-temps.<br/>
            Laissez vos joueurs consulter les meilleurs moments directement dans votre club house.<br/>
            <br/>
            <br/>
            Ludique et pédagogique, ce système vidéo renforcera les liens avec vos adhérents, et valorisera vos
            sponsors.
            <br/><br/><br/>

          </p>
        </div>
      </div>


      <div className={"page-instruction"}>
        <h1 style={{ color: "#1CD8D2", textAlign: "center" }}>Booking</h1>
        <span style={{
          color: "#1CD8D2"
        }}
              className={"with-mg"}
        >
                    Remplissez vos créneaux
                </span>
      </div>

      <div id={"booking"} className={"page-content"} style={{ flexDirection: "row-reverse", position: "relative" }}>
        <svg
          className={"bg-func-side"}
          preserveAspectRatio={"none"}
          width="133" height="785" viewBox="0 0 133 785" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M432 385.5C212 701.5 332 829.5 216 771C100 712.5 0 598.406 0 385.5C0 172.594 96.7065 0 216 0C335.294 0 652 69.5 432 385.5Z"
            fill="url(#paint5_linear)"/>
          <defs>
            <linearGradient id="paint5_linear" x1="253.926" y1="0" x2="253.926" y2="784.757"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#1CD8D2"/>
              <stop offset="1" stopColor="#B7B6FF" stopOpacity="0.67"/>
            </linearGradient>
          </defs>
        </svg>


        <div className={"image-container"}>
          <img
            src={require("../images/content/booking-1.svg")}
            alt={"planning"}
          />
        </div>


        <div className={"page-instruction"}>
          <span style={{ color: "#1CD8D2" }}>Limitez les créneaux vides</span>
          <p>
            Evitez les annulations de dernières minutes !!<br/>
            Notre système vous permet de savoir en amont le nombre de participant <br/>
            d’une réservation.<br/>
            A l’aide de notre application, s’il manque un joueur, avertissez tous vos clients.<br/>
            Un créneau libre ou un désistement, utilisez l’application mobile dédiée pour lancer un Fast Booking...<br/>
            Toute la communauté sera avertie immédiatement, vous réduirez ainsi les trous dans votre planning.<br/>
            <br/>
          </p>

          <span style={{ color: "#1CD8D2" }}>Simplifier l'organisation des matchs</span>
          <p>
            Nos applications facilitent la prise de réservation et l’organisation de matchs entre amis ou avec de nouveaux joueurs.<br/>
            <br/>
            Nous mettons à disposition une application mobile gratuite pour les joueurs qui souhaite réserver un terrain dans un de nos centres partenaires.<br/>
            Ils pourront également de trouver des joueurs afin de compléter les matchs<br/>
            ou encore retrouver les parties créées par les centres partenaires.<br/><br/>
          </p>
        </div>
      </div>

      <div className={"page-content"}>
        <div className={"image-container"}>
          <img
            style={{
              width: 253,
              height: 485
            }}
            src={require("../images/content/mobile.png")}
            alt={"planning"}
          />
        </div>

        <div className={"page-instruction"}>

          <img src={require("../images/content/book.svg")}
               id={"book-fun"}
               alt={"calendar"}/>

          <span style={{ color: "#1CD8D2" }}>Les joueurs ont accès à leurs statistiques de jeu</span>
          <p>
            Depuis l’application mobile, les joueurs pourront retrouver de nombreuses<br/>
            informations sur leurs rencontres.<br/>
            <br/>
            Résultats des matchs, statistiques jeu, ou encore vidéo des plus belles<br/>
            actions !<br/>
            <br/>
            <br/>
            Rien de tel pour fidéliser vos clients et les inciter à revenir.<br/>
            <br/>
            <br/>
          </p>

          <span style={{ color: "#1CD8D2", fontSize: "2.2rem" }}>Développer votre activité sans efforts</span>

        </div>
      </div>


      <div className={"page-instruction"}>
        <h1 style={{ color: "#4B48F4", textAlign: "center" }}>Abonnements</h1>
        <span style={{
          color: "#4B48F4"
        }}
              className={"with-mg"}
        >
                Fidélisez vos clients
                </span>
      </div>

      <div id={"abonnement"} className={"page-content"} style={{ position: "relative" }}>
        <svg
          preserveAspectRatio={"none"}
          className={"bg-func-side"} width="175" height="785" viewBox="0 0 175 785" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M432 385.5C212 701.5 332 829.5 216 771C100 712.5 0 598.406 0 385.5C0 172.594 96.7065 0 216 0C335.294 0 652 69.5 432 385.5Z"
            fill="url(#paint6_linear)"/>
          <defs>
            <linearGradient id="paint6_linear" x1="253.926" y1="0" x2="253.926" y2="784.757"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#4B48F4"/>
              <stop offset="1" stopColor="#B7B6FF" stopOpacity="0.67"/>
            </linearGradient>
          </defs>
        </svg>


        <div className={"image-container"}>
          <img
            src={require("../images/content/abonnement-1.svg")}
            alt={"planning"}
          />
        </div>

        <div className={"page-instruction"}>


          <span style={{ color: "#4B48F4", opacity: 0.67 }}>Générer du chiffre d’affaires récurrent</span>
          <p>
            La gestion des abonnements présente au moins deux avantages : <br/>
            le premier est des revenus constants. <br/>
            Ensuite, notamment grâce à l’utilisation de la data, l’étude du comportement des abonnés permet de prévoir
            la manière dont les clients se désabonnent et s’abonnent beaucoup plus facilement.
            <br/><br/>
            Avec notre système, créez autant de type d’abonnement que vous souhaitez. <br/>
            Carte prépayée, abonnement mensuel, paiement en 3 ou fois et davantage......<br/>
            Suivez les cycles de réabonnement automatiquement.
          </p>
        </div>
      </div>

      <div className={"page-content"} style={{ flexDirection: "row-reverse" }}>
        <div className={"image-container"}>
          <img
            src={require("../images/content/abonnement-2.svg")}
            alt={"abonnement"}
          />
        </div>

        <div className={"page-instruction left-mg"} style={{}}>
          <img src={require("../images/content/cardB.svg")}
               id={"card-fun2"}
               alt={"calendar"}/>
          <span style={{ color: "#4B48F4", opacity: 0.67 }}>Fidélisez vos clients et gagnez du temps</span>
          <p>
            Fidélisez vos clients grâce aux abonnements et aux fonctionnalités de programmes personnalisés. <br/>
            <br/>
            Optimisez la gestion de votre temps.<br/>
            Vos abonnements seront gérés plus facilement au quotidien.<br/>
            Par exemple vos cartes de fidélité et abonnement sont directement accessible sur les smartphones de vos clients via notre application.<br/>
            L’abonnement vient simplifier le processus d’achat en l’automatisant.<br/>
            <br/>
            Vous pourrez ainsi nouer des relations durables avec vos clients.<br/>
            <br/>
          </p>
        </div>
      </div>


      <div className={"page-instruction"}>
        <h1 style={{ color: "#52C234", textAlign: "center" }}>Compétition</h1>
        <span
          style={{ color: "#52C234" }}
          className={"with-mg"}>
              Donnez à vos compétitions
              une nouvelle dimension
                </span>
      </div>

      <div id={"competition"} className={"page-content"}>

        <svg
          preserveAspectRatio={"none"}
          className={"bg-func-side"}
          width="195" height="785" viewBox="0 0 195 785" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M119 385.5C-101 701.5 19 829.5 -97 771C-213 712.5 -313 598.406 -313 385.5C-313 172.594 -216.294 0 -97 0C22.2935 0 339 69.5 119 385.5Z"
            fill="url(#paint7_linear)"/>
          <defs>
            <linearGradient id="paint7_linear" x1="-59.0745" y1="0" x2="-59.0745" y2="784.757"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#52C234"/>
              <stop offset="1" stopColor="#52C234" stopOpacity="0.14"/>
            </linearGradient>
          </defs>
        </svg>


        <div className={"image-container"}>
          <img
            src={require("../images/content/competition-1.svg")}
            alt={"planning"}
          />
        </div>

        <div className={"page-instruction"}>
          <span style={{ color: "#52C234", opacity: 0.67 }}>Automatisez vos compétitions</span>
          <p>
            Une autre expérience avec le moteur de gestion le plus abouti qui vous surprendra.<br/>
            Notre module "compétition" permet de gérer la partie sportive depuis la création du calendrier jusqu'aux résultats et classements.<br/>
            <br/>
            Gagnez du temps, du travail et des efforts en laissant le paramètrage des journées, le calculs des points et le classement se faire automatiquement. <br/>
            <br/>
            Vous profiterez d'une solution avancée avec une multitude de fonctionnalités : des points, des buts, des cartons, des passes, CSC … <br/>
            Contrôlez chaque aspect et offrez vous une meilleure gestion des résultats.<br/>
            <br/>
          </p>
        </div>
      </div>

      <div className={"page-content"} style={{ flexDirection: "row-reverse" }}>
        <div className={"image-container"} style={{ alignSelf: "flex-start", flex: 1 }}>
          <img
            src={require("../images/content/competition-2.svg")}
            alt={"abonnement"}
          />
        </div>

        <div className={"page-instruction"}>

          <img src={require("../images/content/trophy.svg")}
               id={"trophy-fun"}
               alt={"calendar"}/>

          <span style={{
            color: "#52C234",
            opacity: 0.67
          }}>Offrez à vos compétiteurs toutes leurs statistiques</span>
          <p>
            Permettez un accès privé ou public aux équipes, à toutes les statistiques individuelles et collectives.<br/>
            Les scores sont accessibles en temps réel.<br/>
            Avec l’application scoring, tout se rempli automatiquement du score au classement, sans oublier les palmarès (meilleur buteur, meilleure attaque ....).<br/>
          </p>
        </div>
      </div>


      <div className={"page-instruction"}>
        <h1 style={{ color: "#AE5B8C", textAlign: "center" }}>Scoring</h1>
        <span className={"with-mg"} style={{
          color: "#AE5B8C"
        }}>
Affichage numérique adapté à tous vos sports.
                </span>

        <img src={require("../images/content/scoring.svg")}
             id={"score-fun"}
             alt={"calendar"}/>
      </div>

      <div id={"scoring"} className={"page-content"} style={{ display: "relative" }}>
        <svg
          preserveAspectRatio={"none"}
          className={"bg-func-side"}
          width="95" height="785" viewBox="0 0 95 785" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M432 385.5C212 701.5 332 829.5 216 771C100 712.5 0 598.406 0 385.5C0 172.594 96.7065 0 216 0C335.294 0 652 69.5 432 385.5Z"
            fill="url(#paint8_linear)"/>
          <defs>
            <linearGradient id="paint8_linear" x1="253.926" y1="0" x2="253.926" y2="784.757"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#F44881"/>
              <stop offset="1" stopColor="#B7B6FF" stopOpacity="0.67"/>
            </linearGradient>
          </defs>
        </svg>

        <div className={"image-container"}>
          <img
            src={require("../images/content/scoring-1.png")}
            alt={"scoring 1"}
          />
        </div>

        <div className={"page-instruction"} style={{ alignItems: "flex-start" }}>



                    <span
                      style={{ color: "#AE5B8C", opacity: 0.67 }}>Scorez et visualisez vos résultats directement</span>
          <p>
            Nous proposons un scorer (tactile ou non) pour dynamiser les rencontres.<br/>
            Accessible à tous, il permet de suivre le score des matchs et de multiples données en toute simplicité.<br/>
            Nos afficheurs permettent l’affichage des scores, du chronomètre et les noms des équipes.<br/>
            <br/>
            Vos clients visualisent leur rencontre dans les meilleurs conditions, avec l'évolution du score.<br/>
            Il s’adapte à tous vos espaces sportifs et peut être couplé à plusieurs autres services mobiles et vidéos (séquencage des actions).<br/>
            <br/>
          </p>
        </div>

      </div>


      <div className={"page-content"}>

        <div className={"page-instruction"} style={{ alignItems: "flex-start" }}>
          <span style={{ color: "#AE5B8C", opacity: 0.67 }}>Valoriser vos partenaires</span>
          <p>
            Nous avons conçu des applications mobiles liées avec un écran tactile <br/>
            (TV et tablette 7 à 142 cm) qui permettra d’intensifier vos matchs et valoriser la communication de vos sponsors. <br/>
            <br/>
            Un produit «plug and play» avec un large choix de connectiques pour gérer les scores,<br/>
            le chronomètre, les fautes, les pénalités ....<br/>
            <br/>
            Ajoutez les logos de vos partenaires ou encore des clips publicitaires, <br/>
            ils défileront en dessous du score, et également en plein écran pendant les moments d'inactivités.<br/>
          </p>
        </div>

        <div className={"image-container"} style={{ margin: "auto", flex: 1 }}>
          <img
            src={require("../images/content/scoring-2.png")}
            alt={"scoring 1"}
          />
        </div>
      </div>

      <div className={"page-scoring"}>
        <div className={"page-instruction-items"}>
          <div className={"page-instruction-item"}>
            <img src={require("../images/wifi.svg")} alt={"wifi"}/>
            <p>Ecran connecté <br/>tactile</p>
          </div>

          <div className={"page-instruction-item"}>
            <img src={require("../images/live.svg")} alt={"live"}/>
            <p>Diffusion en live , replay <br/> et streaming</p>
          </div>

          <div className={"page-instruction-item"}>
            <img src={require("../images/camera.svg")} alt={"camera"}/>
            <p>Séquencage buts <br/> et actions</p>
          </div>

          <div className={"page-instruction-item"}>
            <img src={require("../images/pub.svg")} alt={"pub"}/>
            <p>Ambiance sonore<br/> et publicité</p>
          </div>

          <div className={"page-instruction-item"}>
            <img src={require("../images/soc.svg")} alt={"scoring"}/>
            <p>Statistiques<br/> de jeu</p>
          </div>
        </div>
      </div>

      <button className={"btn-demo"}>Demandez une démonstration</button>


    </div>

    <Footer>
      <div className={"home-menu"} style={{ alignItems: "center", justifyContent: "center" }}>
        {
          menu.map((it, i) => (
            <Link to={"#" + it.url} key={"small-index-menu-" + i}>
              <div
                className={"small-menu"}
                style={{ background: it.background }}
              >
                <img
                  src={require("../images/" + it.url + ".svg")}
                  alt={it.name}
                />
                <span>{it.name}</span>
              </div>
            </Link>
          ))
        };
      </div>
    </Footer>
  </Layout>
)

export default IndexPage
